<template>
  <div :key="updatedAt">
    <FilterGroups 
      :gruposCampanha="vacina.estimativa"
      @gruposFitlrados="handleFiltroGrupo"
    />
    <app-collapse>
      <app-collapse-item
        v-for="(grupo, index) in gruposFiltrados"
        :key="grupo.id_grupo"
        :is-visible="index == 0"
        class="mb-2 shadow rounded"
        title="Grupo"
      >
        <template slot="header">
          <TabPrecificacaoHeader
            :vacina="vacina"
            :grupo="grupo"
          />
        </template>

        <b-row>
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <TabPrecificacaoValorEmpresa
              :money-mask="moneyMask"
              :vacina="vacina"
              :grupo="grupo"
            />

            <!-- operadores -->
            <div
              v-for="operador in grupo.campanha_grupo_preco"
              :key="operador.id_grupo_operador"
            >
              <TabPrecificacaoValorOperador
                :money-mask="moneyMask"
                :vacina="vacina"
                :grupo="grupo"
                :operador="operador"
              />
            </div>
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <h5>Documentação e contratos</h5>
            <TabPrecificacaoDocumento :documentos="grupo.campanha_grupo_documento" />
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BForm,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ricon from '@/assets/images/icons/RIcon.svg'
import TabPrecificacaoValorEmpresa from './TabPrecificacaoValorEmpresa.vue'
import TabPrecificacaoValorOperador from './TabPrecificacaoValorOperador.vue'
import TabPrecificacaoDocumento from './TabPrecificacaoDocumento.vue'
import TabPrecificacaoHeader from './TabPrecificacaoHeader.vue'
import FilterGroups from '@/views/custom-pages/campanha-nacional/components/FilterGroup.vue';

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BForm,
    AppCollapse,
    AppCollapseItem,
    TabPrecificacaoValorEmpresa,
    TabPrecificacaoValorOperador,
    TabPrecificacaoDocumento,
    TabPrecificacaoHeader,
    FilterGroups
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
      updatedAt: null,
      grupos: [],

      gruposFiltrados: {},
    }
  },

  mounted() {
    this.gruposFiltrados = this.vacina.estimativa;
  },

  methods: {
    handleFiltroGrupo(gruposFiltrados) {
      this.gruposFiltrados = gruposFiltrados;
    }
  }
}
</script>
