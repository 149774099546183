<template>
  <b-row>
    <b-col lg="12" md="12" sm="12">
      <h5>Operador {{ operador.sigla }}</h5>
    </b-col>

    <!-- Dose + Gesto Vacinal: -->
    <b-col lg="6" md="6" sm="12">
      <b-form-group>
        <label :for="targerForIDFocusDoseGestaoGrupo"
          >Dose + Gesto Vacinal</label
        >
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="bg-custom-blue d-flex align-items-center custom-append-input"
            >
              <b-img :src="Ricon" alt="Icone que representa o real" />
            </div>
          </b-input-group-prepend>

          <b-form-input
            v-model="operador.valor_dose"
            v-money="moneyMask"
            :id="targerForIDFocusDoseGestaoGrupo"
            placeholder="00,00"
            :disabled="disabled"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <!-- Taxa de Gestão: -->
    <b-col lg="6" md="6" sm="12">
      <b-form-group>
        <label :for="targerForIDFocusDoseTaxaGrupo">Taxa de Gestão:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="bg-custom-blue d-flex align-items-center custom-append-input"
            >
              <b-img :src="Ricon" alt="Icone que representa o real" />
            </div>
          </b-input-group-prepend>
          <b-form-input
            v-model="operador.valor_taxa"
            v-money="moneyMask"
            :id="targerForIDFocusDoseTaxaGrupo"
            placeholder="00,00"
            :disabled="disabled"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BForm,
} from "bootstrap-vue";
import Ricon from "@/assets/images/icons/RIcon.svg";
import helpers from "@/helpers";

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BForm,
  },
  props: {
    moneyMask: {
      type: Object,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    operador: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: true,
      Ricon,
      targerForIDFocusDoseTaxaGrupo: helpers.geraNumeroAleatorio().toString(),
      targerForIDFocusDoseGestaoGrupo: helpers.geraNumeroAleatorio().toString(),
    };
  }
};
</script>

