<template>
  <div>
    <!-- listagem grupos selecionados -->
    <div :key="updated">
      <FilterGroups 
        :gruposCampanha="form.gruposSelecionados"
        @gruposFitlrados="handleFiltroGrupo"
      />
      <div class="mb-2 border rounded pt-0 pb-2 px-1">
        <b-row
          v-for="grupo in gruposFiltrados"
          :key="grupo.id_grupo"
          class="px-1"
        >
          <b-col class="border-bottom p-0 d-flex align-items-center" cols="11">
            <h5 class="my-1">{{ grupo.descricao }}</h5>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  CampanhaNacionalGetFormGrupos,
  CampanhaNacionalSetGruposSelecionadoPorVacina,
  CampanhaNacionalGetGruposSelecionadoPorVacina
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FilterGroups from '@/views/custom-pages/campanha-nacional/components/FilterGroup.vue';

export default {
  components: {
    BRow,
    FilterGroups,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    CampanhaNacionalGetFormGrupos,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updated: null,
      form: {
        opcoes: [],
        grupos: [],
        gruposSelecionados: [],
        errors: {
          grupos: null,
        },
      },
      gruposFiltrados: {}
    };
  },

  mounted() {
    this.gruposFiltrados = this.form.gruposSelecionados;
  },

  beforeMount() {
    this.handleOpcoes();
  },

  methods: {
    handleOpcoes() {
      this.form.gruposSelecionados = this.vacina.estimativa;
      CampanhaNacionalSetGruposSelecionadoPorVacina(
        this.vacina,
        this.vacina.estimativa
      )
    },

    handleGruposSelecionados() {
      this.form.gruposSelecionados =
        CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
    },

    handleFiltroGrupo(gruposFiltrados) {
      this.gruposFiltrados = gruposFiltrados;
    }
  },
};
</script>

