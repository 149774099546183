<template>
  <app-collapse-item
    :is-visible="isVisible"
    class="mb-2 shadow rounded"
    title="Grupo"
  >
    <template slot="header">
      <h4 class="text-custom-blue mb-0">
        {{ grupo.descricao }}
      </h4>
      <b-badge
        class="rounded-pill px-1 d-flex justify-content-center align-items-center"
        variant="custom-light-blue"
      >
        <img
          class="text-custom-blue customImageVacinaDistribuicao mr-1"
          :src="VaccineIcon"
          alt="vaccineIcon"
          width="14"
          height="14"
        >
        <strong class="text-custom-blue mr-1">Doses distribuídas:</strong>
        <span class="text-custom-blue font-weight-normal">{{ grupo.quantidade }}</span>
      </b-badge>
    </template>

    <div
      v-for="operador in operadores"
      :key="operador.id_campanha_regiao"
      class="align-between"
    >
      <TabDistribuicaoOperadorInput
        :vacina="vacina"
        :grupo="grupo"
        :operador="operador"
      />
    </div>
  </app-collapse-item>
</template>

<script>
import {
  BBadge,
  BCol,
  BCollapse,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BRow,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VaccineIcon from '@/assets/custom-icons/vaccine.svg'
import TabDistribuicaoOperadorInput from './TabDistribuicaoOperadorInput.vue'

export default {
  setup() {
    return {
      VaccineIcon,
    }
  },
  components: {
    BRow,
    BBadge,
    BCollapse,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormInput,
    BInputGroup,
    TabDistribuicaoOperadorInput,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    operadores: {
      type: Array,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qtdDistribuida: 0,
    }
  },
}
</script>

<style>
  .customImageVacinaDistribuicao {
    transform: scaleX(-1);
  }
</style>
