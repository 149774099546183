<template>
  <div class="content-wrapper" id="campanha-nacional-registro-visualizacao">
    <PageHeader screenName="Visualizar Campanha Nacional" :linkItems="linkItems" />

    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <InfosCampanhaForm v-if="!showLoading" />
      <precification-campaign :vacinas="vacinas"/>
      <div class="card shadow-none">
        <div>
          <b-button
            @click.prevent="redirectToEdit()"
            class="mt-2 m-1 mx-2"
            variant="primary-button"
          >
              <feather-icon
                  icon="EditIcon"
                  class="mr-50"  
              />
            Editar
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
  
<script>
  import { BOverlay, BButton } from "bootstrap-vue";
  import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
  import InfosCampanhaForm from "@/views/custom-pages/campanha-nacional/Show/InfosCampanhaForm.vue";
  import { getIdCampaignEditAndShow } from '@core/utils/store/getStore';
  import { setIdCampaignEditAndShow } from '@core/utils/store/setStore';
  import {
    CampanhaNacionalSetInit,
    CampanhaNacionalSetFormDoencas,
    CampanhaNacionalSetFormGrupos,
    CampanhaNacionalSetDoencaSelecionada,
    CampanhaNacionalSetDescricao,
    CampanhaNacionalSetFormVacinas,
    CampanhaNacionalSetVacinasSelecionada,
    CampanhaNacionalSetDataInicioSelecionado,
    CampanhaNacionalSetDataTerminoSelecionado
  } from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
  import PrecificationCampaign from '@/views/custom-pages/campanha-nacional/Show/PrecificationCampaign.vue';
  

  export default {
    title: "Visualizar Campanha Nacional",
  
    components: {
      BOverlay,
      BButton,
      PageHeader,
      InfosCampanhaForm,
        PrecificationCampaign
    },
  
    data() {
      return {
        linkItems: [
          {
            name: "Campanhas",
            routeName: "campanha-list-nacional",
          },
          {
            name: "Visualizar Campanha Nacional",
            active: true,
          },
        ],
        showLoading: true,
        updatedAt: null,
        payload: {},
        vacinas: [],
      };
    },

    mounted() {
      const hasIdEntityRouteParam = this.$helpers.verifyIfHasIdEntityRouteParam(getIdCampaignEditAndShow(), this.$route.params.idCampanha);
        if (hasIdEntityRouteParam) {
            setIdCampaignEditAndShow(this.$route.params.idCampanha);
        }
      CampanhaNacionalSetFormDoencas();
      CampanhaNacionalSetFormGrupos();
      this.loadDataCampaign(getIdCampaignEditAndShow())
    },
    destroyed(){
      CampanhaNacionalSetInit({
      idUsuarioLogado: null,
      });
    },
    methods: {
        loadDataCampaign(idCampanha) {
            this.$http.get(this.$api.campanhaNacionalId(idCampanha)).then(({ data }) => {
                CampanhaNacionalSetDescricao(data.descricao)
                CampanhaNacionalSetFormVacinas(data.id_doenca)
                CampanhaNacionalSetDoencaSelecionada(data.id_doenca)
                const vacinas = data.campanha_vacina.map(item => ({ id_vacina: item.id_vacina, nome_tecnico: item.nome_tecnico }));
                this.vacinas = data.campanha_vacina
                CampanhaNacionalSetVacinasSelecionada(vacinas)
                CampanhaNacionalSetDataInicioSelecionado(data.inicio)
                CampanhaNacionalSetDataTerminoSelecionado(data.termino)
                this.showLoading = false;
            })
        },
        redirectToEdit() {
          this.$router.push({
            name: "campanha-edit-nacional",
            params: {
              idCampanha: getIdCampaignEditAndShow(),
            },
          });
        },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@/views/custom-pages/campanha-nacional/campanhaNacional.scss";
  </style>