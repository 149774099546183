<template>
  <div :key="updatedAt">
    <FilterGroups 
      :gruposCampanha="grupos"
      @gruposFitlrados="handleFiltroGrupo"
    />
    <app-collapse>
      <div v-for="(grupo, index) in gruposFiltrados" :key="grupo.id_grupo">
        <TabDistribuicaoOperador
          :vacina="vacina"
          :grupo="grupo"
          :operadores="grupo.campanha_regiao"
          :isVisible="index == 0"
        />
      </div>
    </app-collapse>
  </div>
</template>

<script>
import { BBadge, BRow, BCol } from "bootstrap-vue";
import {
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalGetState,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import Ricon from "@/assets/images/icons/RIcon.svg";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import TabDistribuicaoOperador from "./TabDistribuicaoOperador.vue";
import FilterGroups from '@/views/custom-pages/campanha-nacional/components/FilterGroup.vue';

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    TabDistribuicaoOperador,
    AppCollapse,
    FilterGroups
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },

  beforeMount() {
    this.setGrupos();
  },

  methods: {
    setGrupos() {
      let grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
      this.grupos = grupos != undefined ? grupos : [];
      this.updatedAt = CampanhaNacionalGetState().updatedAt;

      this.gruposFiltrados = grupos;
    },

    handleFiltroGrupo(gruposFiltrados) {
      this.gruposFiltrados = gruposFiltrados;
    }
  },
  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      updatedAt: null,
      grupos: [],

      gruposFiltrados: {},
    };
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.grupos": {
      immediate: false,
      handler() {
        this.setGrupos();
      },
    },
  },
};
</script>

