<template>
  <b-row>
    <!-- valor da dose da empresa -->
    <b-col
      lg="6"
      md="6"
      sm="12"
    >
      <b-form-group>
        <label :for="targetForIdFocus">Valor da dose para empresa:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="bg-custom-blue d-flex align-items-center custom-append-input"
            >
              <b-img
                :src="Ricon"
                alt="Icone que representa o real"
              />
            </div>
          </b-input-group-prepend>
          <b-form-input
            :id="targetForIdFocus"
            v-model="grupo.valor_dose"
            v-money="moneyMask"
            placeholder="00,00"
            :disabled="disabled"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      lg="6"
      md="6"
      sm="12"
    >
      <p>
        O valor da dose para empresa é aquele que será cobrado da empresa com
        base no estabelecido em contrato.
      </p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from 'bootstrap-vue'
import Ricon from '@/assets/images/icons/RIcon.svg'
import helpers from '@/helpers'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    moneyMask: {
      type: Object,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: true,
      targetForIdFocus: helpers.geraNumeroAleatorio().toString(),
      Ricon,
    }
  },
}
</script>
