<template>
  <section>
    <b-row class="mr-2">
      <div v-if="documentos.length > 0" class="w-100">
        <b-col
          cols="12"
          v-for="documento in documentos"
          :key="documento.id_campanha_grupo_documento"
          class="d-flex justify-content-between align-items-center bg-light-secondary rounded p-1"
        >
          <span style="align-self: flex-start">{{ documento.descricao }}</span>
          <div>
            <feather-icon
              @click="visualizar(documento)"
              icon="DownloadIcon"
              class="cursor-pointer mr-1"
            />
          </div>
        </b-col>
      </div>
      <p class="pl-1 pt-1 text-muted" v-else>Não há documentos ou contratos.</p>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormFile } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
  },
  props: {
    documentos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      documentoSelecionado: null,
      error: null,
      maxSize: 10000000,
      updatedAt: null,
    };
  },
  methods: {
    visualizar(documento) {
      this.$http
        .get(this.$api.getAnexo(documento.id_anexo), {responseType: 'arraybuffer'})
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", documento.descricao);
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    },
  },
};
</script>
