<template>
  <section class="minimal-information">
    <main class="card p-2 shadow-none">
      <section class="formCampanha mt-2">
          <b-form>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  label="Nome da Campanha*"
                  label-for="name-campanha"
                  class="mb-0"
                >
                    <b-form-input
                      v-mask="'X'.repeat(200)"
                      id="name-campanha"
                      v-model="descricaoSelecionada"
                      autocomplete="off"
                      placeholder="Dê o nome da campanha"
                      :disabled="desabilitar"
                    />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col lg="6" md="6" sm="12">
                  <b-form-group
                    label="Doença*"
                    label-for="doencas"
                  >
                    <v-select
                      id="doencas"
                      :reduce="(option) => option.id_doenca"
                      v-model="form.doencas"
                      variant="primary"
                      :options="formDoencas"
                      label="nome_tecnico"
                      :clearable="false"
                      placeholder="Selecione uma doença"
                      :disabled="desabilitar"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                  <b-form-group
                    label="Vacina*"
                    label-for="vaccines"
                  >
                    <v-select
                      id="vaccines"
                      v-model="form.vacinas"
                      :options="formVacinas"
                      :closeOnSelect="false"
                      multiselect
                      multiple
                      :selectable="() => form.vacinas.length < 3"
                      label="nome_tecnico"
                      placeholder="Selecione uma ou mais"
                      :disabled="desabilitar"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="12">
                  <b-form-group
                    label="Data de Início*"
                    label-for="inicio-input"
                  >
                    <b-input-group>
                      <b-form-input
                        class="form-control"
                        :state="errorOnDate ? false : null"
                        id="inicio-input"
                        v-mask="'##/##/####'"
                        v-model="input.data_inicio"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        @input="setInput($event, 'data_inicio')"
                        :disabled="desabilitar"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          class="form-control d-flex align-items-center"
                          locale="pt-BR"
                          v-model="form.data_inicio"
                          button-only
                          right
                          :hide-header="true"
                          label-help="Selecione a data de início"
                          button-variant="custom-blue"
                          clearable
                          @input="setPicker($event, 'data_inicio')"
                          :disabled="desabilitar"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12">
                  <b-form-group
                    label="Data de Término*"
                    label-for="termino-input"
                  >
                    <b-input-group>
                      <b-form-input
                        class="form-control"
                        :state="errorOnDate ? false : null"
                        id="termino-input"
                        v-mask="'##/##/####'"
                        v-model="input.data_termino"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        @input="setInput($event, 'data_termino')"
                        :disabled="desabilitar"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          class="form-control d-flex align-items-center"
                          locale="pt-BR"
                          v-model="form.data_termino"
                          button-only
                          right
                          :hide-header="true"
                          label-help="Selecione a data de término"
                          button-variant="custom-blue"
                          clearable
                          @input="setPicker($event, 'data_termino')"
                          :disabled="desabilitar"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
      </section>
    </main>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
  BAlert,
  BInputGroup,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import moment from "moment";
import {
  CampanhaNacionalGetState,
  CampanhaNacionalGetFormDoencas,
  CampanhaNacionalGetDoencaSelecionada,
  CampanhaNacionalGetFormVacinas,
  CampanhaNacionalGetVacinasSelecionada,
  CampanhaNacionalSetDescricao,
  CampanhaNacionalGetDescricao,
  CampanhaNacionalSetDataInicioSelecionado,
  CampanhaNacionalGetDataInicio,
  CampanhaNacionalSetDataTerminoSelecionado,
  CampanhaNacionalGetDataTermino
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    BAlert,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  data() {
    return {
      required,
      desabilitar: true,
      form: {
        nameCampanha: null,
        doencas: null,
        vacinas: [],
        data_inicio: null,
        data_termino: null,
        errors: {
          nameCampanha: null,
          doencas: null,
          vacinas: null,
        },
      },
      input: {
        data_inicio: null,
        data_termino: null,
      },
      vaccines: [],
      errorOnDate: false
    };
  },

  mounted() {
    if (CampanhaNacionalGetDoencaSelecionada() != null) {
      this.form.doencas = CampanhaNacionalGetDoencaSelecionada();
    }
    if (CampanhaNacionalGetDataInicio() != null) {
      this.setPicker(CampanhaNacionalGetDataInicio(), "data_inicio");
    }
    if (CampanhaNacionalGetDataTermino() != null) {
      this.setPicker(CampanhaNacionalGetDataTermino(), "data_termino");
    }
    if (CampanhaNacionalGetVacinasSelecionada() != null) {
      this.form.vacinas = CampanhaNacionalGetVacinasSelecionada();
    }
  },
  methods: {
    setDataValues(campo, dataDB) {
      let data = moment(dataDB, "YYYY-MM-DD")
    
      this.form[campo] = dataDB;
      this.input[campo] = data.format("DD/MM/YYYY");

        campo == "data_inicio"
          ? CampanhaNacionalSetDataInicioSelecionado(dataDB)
          : CampanhaNacionalSetDataTerminoSelecionado(dataDB);
    },
    setPicker(data, campo) {
      this.setDataValues(campo, data);
    },
    setInput(data, campo) {
      if (data.length == 10) {
        const dataDB = moment(this.input[campo], "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
        this.setDataValues(campo, dataDB);
      }
    },
  },

  computed: {
    campanhaNacionalState() {
      return CampanhaNacionalGetState();
    },
    formDoencas() {
      return CampanhaNacionalGetFormDoencas();
    },
    formVacinas() {
      return CampanhaNacionalGetFormVacinas();
    },
    descricaoSelecionada: {
      get: () => CampanhaNacionalGetDescricao(),
      set: (value) => CampanhaNacionalSetDescricao(value),
    },
  },
};
</script>