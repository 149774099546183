<template>
    <section id="group-pricing-dose-distribuition">
      <b-tabs class="custom-tab-header">
        <b-tab
          v-for="(vacina, index) in vacinas"
          :key="vacina.id_vacina"
          :active="index == 0"
          itle-link-class="font-tab"
          title-item-class="custom-tab-style"
        >
          <template #title>
            <h4 class="d-sm-inline m-0">{{ vacina.nome_tecnico }}</h4>
          </template>
  
          <b-card class="shadow-none">
            <form-wizard
              ref="refFormWizard"
              color="#2772C0"
              :title="null"
              :subtitle="null"
              class="steps-transparent mb-3 col-9"
            >
              <tab-content title="Grupos" icon="feather icon-database">
                <TabGroup :vacina="vacina" />
              </tab-content>
  
              <tab-content title="Precificação" icon="feather icon-dollar-sign">
                <TabPrecificacao :vacina="vacina" />
              </tab-content>
  
              <tab-content title="Distribuição" icon="feather icon-disc">
                <TabDistribuicao :vacina="vacina" />
              </tab-content>
  
              <template slot="footer" slot-scope="props">
                <ButtonsSteps :stepsData="props"/>
              </template>
            </form-wizard>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>
</template>
  
<script>
  import { BButton, BTab, BTabs, BCard } from "bootstrap-vue";
  import { FormWizard, TabContent } from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import TabPrecificacao from "./Forms/TabPrecificacao.vue";
  import TabDistribuicao from "./Forms/TabDistribuicao.vue";
  import TabGroup from "./Forms/TabGroup.vue";
  import ButtonsSteps from "@/views/components/custom/ButtonsCampanha/ButtonSteps.vue";

export default {
    components: {
      BButton,
      BTab,
      BTabs,
      BCard,
      FormWizard,
      TabContent,
      TabGroup,
      TabPrecificacao,
      TabDistribuicao,
      ButtonsSteps
    },

    props: {
      vacinas: {
        required: true,
        type: Array,
      },
    },

    watch: {
      vacinas: {
        handler() {
          if(this.$refs.refFormWizard){
            this.$refs.refFormWizard.map(wizard => {
              wizard.activateAll();
            })
          }
        },
        deep:true,
        immediate: true,
      }
    }
  };
</script>
  
  