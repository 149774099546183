<template>
  <b-row class="border-bottom my-1">
    <b-col class="col align-middle">
      <h4>{{ operador.sigla_operador }}</h4>
    </b-col>
    <b-col class="col-auto">
      <b-form-group>
        <label
          class="customLabelQtdDistribuicao"
          :for="targetForIdFocus"
        >Qtd de Doses:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="d-flex align-items-center
              custom-append-input
              iconInputQtdDosesDistribuicaoShow
            "
            >
              <img
                class="text-custom-blue customImageVacinaDistribuicao mr-1"
                :src="VaccineIcon"
                alt="vaccineIcon"
                width="14"
                height="14"
              >
            </div>
          </b-input-group-prepend>
          <b-form-input
            :id="targetForIdFocus"
            v-model="operador.quantidade"
            class="inputQtdDosesDistribuicaoShow"
            placeholder="0"
            :disabled="disabled"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormInput, BFormGroup, BInputGroup, BRow, BInputGroupPrepend,
} from 'bootstrap-vue'
import VaccineIcon from '@/assets/custom-icons/vaccine.svg'

import helpers from '@/helpers'

export default {
  setup() {
    return {
      VaccineIcon,
    }
  },
  components: {
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    operador: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      targetForIdFocus: helpers.geraNumeroAleatorio().toString(),
      disabled: true,
    }
  },
}
</script>
<style>
  .inputQtdDosesDistribuicaoShow {
    max-width: 70px;
    max-height: 36px;
    background: #efefef;
    border-color: #2772C0;
    border-left: none;
    padding: 5px 5px 5px 12px;
  }

  .iconInputQtdDosesDistribuicaoShow {
    max-width: 30px;
    max-height: 36px;
    margin-right: 1px;
    background: #efefef;
    border: 1px solid #2772C0;
    border-color: #2772C0;
    border-right: none;
    border-radius: 5px 0 0 5px;
  }

  .iconInputQtdDosesDistribuicaoShow > img {
    margin-left: 8px;
  }

  .customLabelQtdDistribuicao {
    font-size: 0.7rem;
    margin: 0 10px 0 12px;
  }
</style>
